.Header {
  display: flex;
  max-width: 1080px;
  width: 100%;
}

.header-contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* hide scroll bar for Chrome, Safari and Opera*/
  &::-webkit-scrollbar {
    display: none;
  }
}

.logo-wrapper {
  position: relative;
  height: 2.5rem;
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover > .dropdown-menu,
  .dropdown-menu:hover {
    visibility: visible;
  }
}

.Header-logo {
  height: 100%;
  width: auto;
}
