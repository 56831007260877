.post-item-container {
  border-bottom: 1px solid #d3d0d0;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .post-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0.938rem;
    font-size: 0.813rem;
  }
  .post-title {
    font-size: 1.125rem;
    font-weight: 700;
    color: #222222;
  }
  &:hover {
    cursor: pointer;
  }
}

.post-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 150px;
    height: 100px;
  }
}

.post-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
  }
}

.post-league-wrapper {
  display: flex;
  .post-league {
    color: #ffffff;
    background: #222222;
    border-radius: 4px;
    padding: 0.25rem 0.625rem;
  }
}

.details-wrapper {
  color: #b0b0b0;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  .post-datetime {
    padding-right: 1.25rem;
  }
  .post-author {
    padding-right: 1.25rem;
  }
  .post-view-count {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
}

.post-item-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
}

@media screen and (max-width: 480px) {
  .post-thumbnail > img {
    width: 100px;
    height: 60px;
  }
  .post-item-container .post-title {
    font-size: 0.75rem;
  }
}
