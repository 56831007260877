.player-profile-detail-wrapper {
  border: 1px solid #e9e9e9;
  padding: 1.25rem;
}

.player-profile-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.7;
  margin-bottom: 2.5rem;
  span {
    width: 25rem;
    font-size: 18px;
    font-weight: 500;
    font-family: 'GmarketSansTTFBold';
    color: #000000;
  }
  a {
    font-size: 14px;
    color: #171e4f;
    text-decoration: none;
  }
}

.player-profile-detail {
  display: flex;
  flex-direction: column;
  gap: 2.375rem;
}

.player-detail-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    flex: 1;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #dbdbdb;
    height: 2.5rem;
    padding-left: 12px;
  }
  img {
    width: 60%;
  }
}

.player-detail-item-title {
  font-size: 15px;
  line-height: 1.7;
  width: 9.875rem;
  color: #3a3a3a;
}

.player-detail-item-content {
  display: flex;
  gap: 1.25rem;
  flex: 1;
  width: 100%;
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
}

.player-detail-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin: 1.25rem 0;
  div {
    background: #171e4f;
    color: #ffffff;
    padding: 0.313rem 0.625rem;
    border-radius: 5px;
    font-weight: 700;
    &:hover {
      cursor: pointer;
      color: #ff5722;
    }
  }
}

.profile-notice {
  font-style: italic;
  color: #545454;
  font-size: 14px;
}

#profile-photo {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;
  align-items: center;
  color: #545454;
  .MuiButtonBase-root {
    background-color: #171e4f;
  }
}
