.match-box {
  background-color: #fff;
  margin: 1rem 0.5rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
}

.match-box-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: #e7e7e7 solid 1px;
}

.match-box-header-item {
  font-weight: 600;
  padding: 0.5rem;
  color: #171e4f;
}

.match-box-detail-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.match-box-detail-header {
  width: 100%;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem;
  color: #171e4f;
}

.match-box-detail-match-name {
  width: 100%;
  font-size: 0.7rem;
  font-weight: 600;
  color: #171e4f;
}

.match-box-detail {
  display: grid;
  column-gap: 1rem;
  margin-bottom: 1rem;
  margin: 0.5rem;
  grid-template-columns: 1fr 0.5fr 1fr;
  width: 100%;
}

.score-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #171e4f;
}

.team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-title {
  font-weight: 600;
  color: #171e4f;
  padding-top: 0.5rem;
}

.temp-icon {
  height: 5rem;
  width: 5rem;
}

.match-box-venue-time {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #171e4f;
  font-size: 0.8rem;
  font-weight: 600;
}
