.account-box-wrapper {
  width: 100%;
  max-width: 1080px;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;

  .account-box {
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      position: relative;
      margin-top: 4px;
      right: 240px;
  
      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: -8px;
      //   width: 0;
      //   height: 0;
      //   border-left: 8px solid transparent;
      //   border-right: 8px solid transparent;
      //   border-bottom: 8px solid white;
  }
}
