.images-wrapper {
  display: grid;
  column-gap: 1rem;
  margin-bottom: 1rem;
  margin: 0.5rem;
  padding: 0.5rem;

  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.grid-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;

  span {
    font-size: 1rem;
    font-weight: 700;
    color: #171e4f;
    margin: 0.5rem 0;
  }
  .carousel-root {
    width: 100%;
  }
}

.slide,
.slide-selected {
  display: flex;
  justify-content: center;
}

.mvp-image {
  width: 100%;
  min-width: 0;
  margin-bottom: 2rem;
}

.carousel-item {
  width: 100%;
  min-width: 0;
}

.shooting-chart-container {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
  display: flex;

  @media only screen and (max-width: 768px) {
    min-height: calc(100vw) * 1.33;
  }

  #wrapper {
    min-height: 600px;
  }
}

.shooting-chart-player {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  color: white;
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    min-height: 580px;
  }

  iframe {
    height: 100%;
    min-height: 0;
    border: none;
  }
}
