.schedule-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8rem;
  height: 6rem;
  min-width: 12rem;
  padding: 0.5rem;
  border-right: #bdbdbd solid 1px;
  background-color: #ffffff;
  line-height: 1.5;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
}

.schedule-content .league-name {
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  color: #171e4f;
}
.schedule-content .team-info {
  display: flex;
  justify-content: space-between;
}

.schedule-content .team-info .team-name {
  font-weight: 600;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.match-etc {
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
