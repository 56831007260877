.landing-page-logo-wrapper {
  height: 2.5rem;
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}

.home-item {
  color: #ffffff;
  padding: 1rem 1.5rem;
  padding-top: 1.5rem;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    color: #ff5722;
  }
}

.landing-home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.landing-home-banner-wrapper {
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  img {
    margin: 0 0.3rem;
    max-width: 100%;
    height: auto;
  }
}

.sub-content-box {
  max-width: 1080px;
  column-gap: 1.5rem;
  margin: 0.5rem;
  margin-top: 1.5rem;
  display: grid;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
  }

  img {
    max-height: 250px;
  }
}

.main-league-content-box {
  max-width: 1080px;
  column-gap: 1.5rem;
  margin: 0.5rem;
  margin-top: 1.5rem;
  display: grid;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
  }
}

.main-grid-item-wrapper {
  position: relative;
  width: 100%;
  min-width: 0;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      margin: 0.5rem 0;
      padding-left: 0.5rem;
      text-align: center;
    }
  }

  span.small-text {
    font-size: 16px;
    font-weight: 400;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
    text-align: center;
    span {
      font-size: 100%;
    }
    span.small-text {
      font-size: 100%;
    }
  }
}

.grid-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem;
}

.academy,
.events-league {
  border-radius: 14px;
}

.landing-page-wrapper {
  background-color: #000000;
}

.landing-page-wrapper a {
  text-decoration: none;
}
