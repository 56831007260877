.bench-players-wrapper {
  padding: 0.5rem 1.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box;
  height: -webkit-calc((60vw - 1rem) * 8/15 - 9rem);
  height: calc((60vw - 1rem) * 8 / 15 - 9rem);
  max-height: calc(456px - 9rem);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #a0a0a0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    border-radius: 10px;
    background-color: transparent;
  }

  @media screen and (max-width: 860px) {
    height: 17.25rem;
  }
}

.bench-player {
  padding: 0.3rem 0 0.3rem 0.6rem;
  border: 1px solid #d2d1d1;
  transition: all ease 0.5s;
  border-radius: 5px;
  color: white;
  background-color: #0b1133;
  &:hover {
    cursor: pointer;
    color: #ff5722;
    transform: scale(1.1);
  }
  display: flex;
  gap: 1rem;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0.4rem 0 0.4rem 0.6rem;
  }
}

.bench-back-number {
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
  width: 1.8rem;
  text-shadow: 1px 1px #ff5722;
  display: flex;
  justify-content: center;
  margin-left: 0.3rem;

  @media screen and (max-width: 768px) {
    width: 1rem;
  }
}
