.HeaderWrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 3.5rem;
  background-color: #171e4f;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  z-index: 50;
}
