.news-page-wrapper {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .news-post-list-wrapper {
    width: 100%;
    max-width: 1080px;
    margin: 30px auto;
    border-top: 8px solid #b7b7b7;
    background: #ffffff;
  }
}

.news-search-bar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1084px;
  height: 80px;
  background: #171e4f;
  margin: 30px auto 0;
  border-radius: 4px;
}

.posts-loader {
  display: flex;
  justify-content: center;
  margin: 5rem 0;
}

.no-posts-container {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
  align-items: center;
}
