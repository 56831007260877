.statistics-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  min-height: calc(100vh - 10rem);
  height: 100%;
}

.stat-search-bars-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.stat-search-bar {
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  width: 100%;
  justify-content: flex-start;
  .MuiInputBase-root {
    font-size: 0.9rem;
  }
}

.stat-data-grid {
  max-width: 1600px;
  width: 100%;
  margin: 0.5rem;
  // display: flex;
  // flex-direction: column;
  .MuiDataGrid-root {
    font-size: 0.7rem !important;
  }
}

.search-guide {
  text-align: center;
  margin-top: 3.125rem;
  font-size: 24px;
  font-weight: 500;
}
