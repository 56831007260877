.schedule-bar-wrapper {
  margin: 0.5rem;
  max-width: calc(1080px - 1rem);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.schedule-title {
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 0.5rem;
  border-right: #bdbdbd solid 1px;
  background-color: #ffffff;
  color: #171e4f;
  font-weight: 700;
  line-height: 1.5;
}
.schedule-title-wrapper {
  width: 8rem;
  text-align: center;
}

.schedule-bar {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  overflow: auto;
  white-space: nowrap;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* hide scroll bar for Chrome, Safari and Opera*/
.schedule-bar::-webkit-scrollbar {
  display: none;
}

.schedule-bar-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-right: #bdbdbd solid 1px;
  background-color: #ffffff;
}

.no-content-div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #ff5722;
  width: 100%;
}
