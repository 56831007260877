.notice-post-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8px;
  border-bottom: 1px solid #d3d0d0;
  background: #f8f8f8;
  & > * {
    padding: 10px 0;
  }
}

.notice-title {
  flex-grow: 1;
  padding-left: 15px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}

.notice-icon {
  min-width: 9.375rem;
  color: #ff5722;
}

.notice-datetime {
  width: 82px;
  display: inline-flex;
  justify-content: center;
  color: #b0b0b0;
}

.notice-author {
  margin-right: 15px;
  font-weight: 500;
  color: #474545;
}
