.select-starters-button-wrapper {
  text-align: center;
  button {
    padding: 0.5rem 1rem;
    font-weight: 700;
    background-color: #ffe662;
    border: 3px solid #000000;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
      background-color: #73731c;
    }
    @media screen and (max-width: 680px) {
      font-size: 0.7rem;
    }
    @media screen and (max-width: 425px) {
      padding: 0.1rem 0.1rem;
    }
  }
}
