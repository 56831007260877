.match-photos-container {
  position: relative;
  max-width: 100%; // Fill the full width of the container
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .preview-photo {
    width: 450px; // Adjust as needed
    opacity: 0.5;
    transition: opacity 0.3s;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .current-photo {
    img {
      max-width: 600px; // Adjust as needed
      height: auto;
      display: block;
      margin: 0 10px;

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (max-width: 768px) {
        max-width: 280px; // Adjust as needed
      }
    }
  }

  .photo-nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &.prev {
      left: 10px;
    }

    &.next {
      right: 10px;
    }
  }
}

.photo-gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; // Padding for spacing around the content
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-height: 80vh; // Maximum height set to 80% of the viewport height
  overflow-y: auto; // Enables vertical scrolling
  width: 90%; // Set the width to utilize full modal width
}

.gallery-item img {
  width: 100%; // Image takes full width of the grid item
  height: auto;
  display: block;
}

.close-gallery {
  position: absolute;
  top: 20px;
  right: 40px;
  background: none;
  border: none;
  color: white;
  font-size: 48px;
  cursor: pointer;
}
