.default-header-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 4.5rem;
  background-color: #1b1b1b;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  z-index: 20;
}

.default-page-header {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}

.default-page-header-logo {
  height: 100%;
  width: auto;
}

.default-page-header-contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  white-space: nowrap;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* hide scroll bar for Chrome, Safari and Opera*/
  &::-webkit-scrollbar {
    display: none;
  }
}

.default-page-logo-wrapper {
  height: 2.5rem;
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}
