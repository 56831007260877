.league-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.league-menu-wrapper {
  position: sticky;
  top: 3.5rem;
  background-color: #f6f6f6;
  max-width: 1080px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  overflow: auto;
  white-space: nowrap;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* hide scroll bar for Chrome, Safari and Opera*/
.league-menu-wrapper::-webkit-scrollbar {
  display: none;
}

.league-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
