.players-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  .player {
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;

    input {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }
  }
  max-height: 50vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #a0a0a0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    border-radius: 10px;
    background-color: transparent;
  }
}
