.news-detail-page-container {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  width: 100%;
  max-width: 1080px;
}

.post-loader {
  display: flex;
  justify-content: center;
  margin: 5rem 0;
}

.back-to-home-button-container {
  margin-top: 2rem;
  margin-bottom: 1.875rem;
  .back-to-home-button {
    background-color: #171e4f;
  }
}

.news-detail-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 1.875rem 3.125rem 2.25rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}

.news-detail-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 83px;
    height: 83px;
  }
}

.news-title {
  font-weight: 700;
  font-size: 2.5rem;
}

.news-detail-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d8d8d8;
  height: 3.125rem;
  color: #d8d8d8;
  .news-detail-author {
    font-size: 15px;
    padding-right: 2.5rem;
    color: #545454;
  }
}

.news-detail-content-wrapper {
  margin: 3rem 0;
  .news-detail-content {
    line-height: 1.6;
    * {
      max-width: 100%;
    }
  }
}

.news-detail-counts {
  display: flex;
  justify-content: flex-end;
  .view-count {
    padding-left: 0.75rem;
  }
}

@media screen and (max-width: 1120px) {
  .news-detail-page-container {
    width: 95%;
    margin: 1rem auto;
  }
}

@media screen and (max-width: 480px) {
  .news-title {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  .news-detail-title-wrapper img {
    width: 45px;
    height: 45px;
  }
  .news-detail-container {
    padding: 1rem;
  }
}
