//@import url('https://fonts.googleapis.com/css2?family=Antonio&display=swap');

.dropdown-menu {
  position: absolute;
  background-color: white;
  z-index: 99;
  top: 100%;
  box-shadow: 0 3px 5px -1px #ccc;
  white-space: nowrap;
  visibility: hidden;

  ul {
    margin: 20px 40px;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 10px 0;

    a {
      color: black;
      text-decoration: none;
      &:hover {
        color: #ff5722;
        padding-left: 8px;
        border-left: 2px solid #ff5722;
        transition: all 0.3s ease;
      }
      font-size: 1.3rem;
      font-weight: 700;
      //font-family: 'Antonio', sans-serif;
    }
  }
}

.close-button-wrapper {
  text-align: end;
  padding-right: 2rem;
  padding-top: 0.5rem;
  display: none;
}

.close-button {
  font-size: 2rem;
  font-weight: 900;
  padding: 0.5rem;
  color: black;
}

@media screen and (max-width: 769px) {
  .dropdown-menu {
    width: 100vw;
    height: 0;
    left: 0;
    text-align: center;
    overflow-y: scroll;
    transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
    transition-delay: 100ms;
    opacity: 0;
    ul {
      margin: 0;
    }
    li {
      font-size: 1.5rem;
      padding: 1.5rem 0;
      border-bottom: 1px solid #ccc;
      a {
        font-size: 2.3rem;
      }
    }
  }

  .logo-wrapper:hover > .dropdown-menu {
    height: 100vh;
    opacity: 1;
  }

  .close-button-wrapper {
    display: block;
  }

  .HeaderWrapper .Header .logo-wrapper .closed {
    height: 0;
    opacity: 0;
  }
}
