.player-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.player-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}

.player-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.player-name,
.player-team {
  span {
    font-weight: 600;
    padding-right: 0.5rem;
  }
}

.player-request-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.modal-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding-top: 1.25rem;
}
