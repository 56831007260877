.banner-staff-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: calc((1080px - 1rem) * 0.49);
  padding: 0.5rem;
}

.banner-staff {
  display: flex;
  flex-direction: column;
  font-family: 'Cafe24Simplehae';
  line-height: 1.25;
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
  max-width: 50%;

  .staff-title {
    display: flex;
    justify-content: center;
  }

  .staff-person {
    display: flex;
    margin-top: 0.5rem;
    justify-content: center;
  }
}
