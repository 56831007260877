.delete-players-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  border: 1px solid red;
  border-radius: 4px;
  font-weight: 700;
  color: red;
  &:hover {
    cursor: pointer;
    border: 1px solid #fa8a68;
    color: #fa8a68;
  }
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
}
