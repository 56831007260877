.account-page-wrapper {
  margin: 4.688rem auto;
  width: 95%;
  max-width: 1080px;
  display: flex;
  flex-wrap: wrap;
  gap: 1.375rem;
}

.account-menu-wrapper {
  flex: 1;
  min-width: 200px;
}

.account-menu {
  //width: 15.625rem;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.account-menu-title {
  background: #171e4f;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 2;
  padding-left: 2.5rem;
}

.profile-detail,
.register-form-wrapper {
  flex: 3;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.account-details-wrapper,
.register-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.account-details-title,
.register-form-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 2.2;
  background: #171e4f;
  color: #ffffff;
  padding-left: 1.25rem;
}

.account-details-container,
.register-form {
  padding: 1.563rem 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #ffffff;
}

.account-details-button,
.register-button {
  text-align: center;
  font-weight: 600;
  padding: 0.5rem 0;
  background-color: #03509b;
  color: #ffffff;
  width: 9rem;
  margin: 0 auto;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    cursor: pointer;
    background-color: #171e4f;
  }
}
