.account-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.313rem;
  &:hover {
    cursor: pointer;
  }
}

.account-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
