.game-result .team-wrapper {
  width: auto;
  .MuiButtonBase-root {
    line-height: 1.125;
    font-size: 2.5rem;
    font-weight: 800;

    @media screen and (max-width: 768px) {
      font-size: 1.7rem;
    }

    @media screen and (max-width: 426px) {
      font-size: 1rem;
    }
  }
}

.game-result-container {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 1.5rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
  .match-title {
    color: #171e4f;
  }
  .match-name {
    color: #171e4f;
    padding: 0;
  }
  .match-sub-title {
    color: #171e4f;
    font-size: 1.2rem;
  }
  *,
  .MuiButtonBase-root {
    font-family: 'Outfit', sans-serif;
  }
}

.match-sub-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.game-result-wrapper {
  margin: 1.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 0.75rem;
  .game-sponsor-banner img {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin: 0.5rem 0;
  }
}

.game-result {
  width: 100%;
  display: grid;
  margin-bottom: 1rem;
  margin: 0.5rem;
  grid-template-columns: 1fr minmax(max-content, 3fr) 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  align-items: center;
}

.match-detail-wrapper,
.match-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.game-score {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    font-size: 3.3rem;
    font-weight: 700;
  }
}

.home-logo,
.away-logo {
  height: 10rem;
  width: 10rem;
  margin: 0.5rem;
  @media only screen and (max-width: 680px) {
    height: 6rem;
    width: 6rem;
  }
}
