.player-banner-staff-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  max-width: calc((1080px - 1rem) * 0.49);
  // padding: 0.5rem;
}

.player-banner-upper-container {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Cafe24Simplehae';
  line-height: 1.25;
  font-weight: 900;
  font-size: 0.8rem;
  width: 100%;

  .player-banner-upper-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0.1px solid #4a517f;

    .player-banner-upper-container-title {
      display: flex;
      justify-content: center;
      margin-top: 0.2rem;
    }

    .player-banner-upper-container-value {
      display: flex;
      justify-content: center;
      margin-bottom: 0.2rem;
    }
  }
}

.player-banner-lower-container {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Cafe24Simplehae';
  line-height: 1.25;
  font-weight: 900;
  font-size: 0.8rem;
  width: 100%;

  .player-banner-lower-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0.1px solid #4a517f;

    .player-banner-lower-container-title {
      display: flex;
      justify-content: center;
      margin-top: 0.2rem;
    }

    .player-banner-lower-container-value {
      display: flex;
      justify-content: center;
      margin-bottom: 0.2rem;
    }
  }
}
