.account-box {
  position: absolute;
  top: 60px;
  right: 10px;
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.login-box-wrapper {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  
  .login-box {
    padding: 24px;
    width: 100%;
    box-sizing: border-box;

    form {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin: 20px 0;

      input, 
      button {
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
      }

      input {
        height: 40px;
        padding: 8px 12px;
        background: #F9F9F9;
        border: 1px solid #dadce0;
        border-radius: 4px;
        font-size: 14px;
        
        &::placeholder {
          color: #757575;
        }
        
        &:focus {
          outline: none;
          border-color: #171E4F;
        }
      }

      .login-button {
        height: 40px;
        background: #171E4F;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #303C9B;
        }

        &:disabled {
          background-color: #9AA0C7;
          cursor: not-allowed;
        }
      }
    }
  }
}

.login-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-text {
  font-weight: 700;
  font-size: 1.563rem;
  color: #171e4f;
  line-height: 1.8;
}

.login-fail-text {
  font-weight: 400;
  font-size: 0.813rem;
  color: #ff0000;
  line-height: 1.8;
  font-style: italic;
}

.separator {
  margin: 20px 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: #757575;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #E0E0E0;
  }

  span {
    margin: 0 10px;
    font-size: 14px;
  }
}

.oauth-button {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #dadce0;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  .social-icon {
    margin-right: 12px;
  }

  span {
    flex: 1;
    text-align: center;
  }

  &.google {
    color: #757575;
    &:hover {
      background-color: #f8f9fa;
    }
  }

  &.facebook {
    background-color: #1877f2;
    color: white;
    border: none;
    &:hover {
      background-color: #166fe5;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none !important;
    box-shadow: none !important;
  }
}

.login-error {
  color: #d32f2f;
  font-size: 14px;
  margin: 8px 0;
  padding: 8px;
  background-color: #ffebee;
  border-radius: 4px;
  text-align: center;
}

.button-loader {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff3d;
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.login-content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 20px;
  z-index: 1001;
}

.login-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 100%;
  box-sizing: border-box;

  form {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px 0;

    input {
      height: 40px;
      padding: 8px 12px;
      background: #F9F9F9;
      border: 1px solid #dadce0;
      border-radius: 4px;
      font-size: 14px;
      
      &::placeholder {
        color: #757575;
      }
      
      &:focus {
        outline: none;
        border-color: #171E4F;
      }
    }

    .login-button {
      height: 40px;
      background: #171E4F;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #303C9B;
      }

      &:disabled {
        background-color: #9AA0C7;
        cursor: not-allowed;
      }
    }
  }
}

.signup-button {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
    border-color: #999;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.forgot-password-button {
  background: none;
  border: none;
  color: #666;
  font-size: 0.8em;
  padding: 5px;
  cursor: pointer;
  width: 100%;

  &:hover {
    text-decoration: underline;
    color: #333;
  }
}

.google-btn {
  width: 100%;
  height: 42px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #eee;
  }
}

.google-icon-wrapper {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
}

.google-icon {
  width: 18px;
  height: 18px;
}

.btn-text {
  flex-grow: 1;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.87);
}

.facebook-btn {
  width: 100%;
  height: 42px;
  background-color: #1877F2;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0;

  &:hover {
    background-color: #166fe5;
  }
}

.facebook-icon-wrapper {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

.facebook-btn .btn-text {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.or-divider {
  text-align: center;
  margin: 16px 0;
  color: #666;
  position: relative;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: calc(50% - 20px);
    height: 1px;
    background-color: #ddd;
  }
  
  &::before {
    left: 0;
  }
  
  &::after {
    right: 0;
  }
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
