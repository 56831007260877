.about-sketch-text {
  font-size: 13px;
  padding: 0.625rem 1.5rem 0.625rem 2.5rem;
  color: #3a3a3a;
  line-height: 2.3;
}

.register-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 3.125rem;
  gap: 1.25rem;
  flex-wrap: wrap;
  .wait-message {
    color: #3a3a3a;
    font-weight: 600;
  }
}
