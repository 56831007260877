.profile-menu-item-wrapper {
  padding: 0.625rem 0;
  display: flex;
  flex-direction: column;
  div {
    padding-left: 2.5rem;
    color: #545454;
    line-height: 2.3;
    &:hover {
      color: #000000;
      cursor: pointer;
    }
  }
  a {
    text-decoration: none;
    color: #545454;
    padding-left: 2.5rem;
    line-height: 2.3;
    &:hover {
      color: #000000;
      cursor: pointer;
    }
  }
  .active {
    font-weight: 700;
    color: #171e4f;
  }
}
