.change-photo {
  display: flex;
  flex-direction: column;
}

.photo-input-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  .MuiButtonBase-root {
    background-color: #171e4f;
  }
}
