.privacy-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
  
  .privacy-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h1 {
      color: #333;
      margin-bottom: 1rem;
    }

    .last-updated {
      color: #666;
      font-style: italic;
      margin-bottom: 2rem;
    }

    section {
      margin-bottom: 2rem;

      h2 {
        color: #444;
        margin-bottom: 1rem;
      }

      h3 {
        color: #555;
        margin: 1rem 0;
      }

      ul {
        list-style-type: disc;
        margin-left: 2rem;
        margin-bottom: 1rem;
      }

      p {
        line-height: 1.6;
        color: #666;
      }
    }
  }
} 