.request-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}

.button-connection {
  background: #171e4f;
  color: #ffffff;
  padding: 0.313rem 0.625rem;
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    color: #ff5722;
  }
}

.player-list-container {
  width: 100%;
}

.player-list-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 1.25rem;
}

.search-bar-wrapper {
  display: flex;
  justify-content: center;
}
