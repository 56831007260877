.match-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.match-content-wrapper {
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rosters {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem;
  .team-roster-wrapper {
    max-width: 100%;
  }
}

.box-score-wrapper {
  background-color: #fff;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  span {
    font-size: 1.5rem;
    font-weight: 700;
    color: #171e4f;
    margin: 0.5rem 0;
  }
  .MuiDataGrid-root {
    font-size: 1rem !important;
  }

  // [aria-colindex="1"] {
  //   background-color: yellow;
  //   position: sticky !important;
  //   left: 0 !important;
  // }

  .MuiDataGrid-footerContainer {
    display: none !important;
  }
}

.carousel .thumbs-wrapper .control-prev.control-arrow,
.carousel .thumbs-wrapper .control-next.control-arrow {
  background-color: rgba(0, 0, 0, 0.3) !important;
  height: 100%;
  top: 0;
}

.match-video-wrapper {
  width: 100%;
}

.match-video {
  margin: 0 auto;
  max-width: 1200px;
}

.notice-message {
  font-family: 'Outfit', sans-serif;
  margin: 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #c61c19;
  text-align: center;
}

.profile-img-container {
  position: relative;
  display: inline-block;
}

.profile-img {
  width: 35px;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.profile-img-hover {
  display: none;
  position: fixed;
  z-index: 1000;
  width: 200px;
  height: auto;
  border-radius: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.profile-img:hover + .profile-img-hover {
  display: block;
}

.profile-img-hover.right-top {
  position: absolute;
  bottom: 0;
  right: 100;
  display: none;
  position: fixed;
  z-index: 1000;
  width: 200px;
  height: auto;
  border-radius: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
