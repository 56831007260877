#select-starters-form {
  display: flex;
  flex-direction: column;
}

.starters-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.select-starters-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .select-starters {
    width: 100%;
  }
  gap: 0.5rem;
}
