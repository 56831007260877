.find-account-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 4.5rem);
  padding: 20px;
  background-color: #f5f5f5;
}

.find-account-content {
  width: 100%;
  max-width: 400px;
}

.find-account-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

.find-account-text-wrapper {
  margin-bottom: 20px;
}

.find-account-text {
  font-weight: 700;
  font-size: 1.563rem;
  color: #171e4f;
  line-height: 1.8;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0;

  .section-title {
    font-weight: 600;
    font-size: 1rem;
    color: #666;
    margin-bottom: 8px;
  }

  input {
    height: 40px;
    padding: 8px 12px;
    background: #F9F9F9;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 14px;
    
    &::placeholder {
      color: #757575;
    }
    
    &:focus {
      outline: none;
      border-color: #171E4F;
    }
  }
}

.submit-button {
  height: 40px;
  background: #171E4F;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #303C9B;
  }

  &:disabled {
    background-color: #9AA0C7;
    cursor: not-allowed;
  }
}

.separator {
  margin: 20px 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: #757575;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #E0E0E0;
  }

  span {
    margin: 0 10px;
    font-size: 14px;
  }
}
