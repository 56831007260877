#logged-in {
  width: 15rem;
  height: fit-content;
}

.account-item-wrapper {
  padding: 1.125rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  font-size: 15px;
}

.account-username-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0.875rem 0;
}

.account-username {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.8;
}

.account-status p {
  margin: 0;
  line-height: 1.5;
}

#account-menu {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 2px 0;
  a {
    color: #000000;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      color: #171e4f;
    }
  }
}

.account-logout {
  padding: 5px 0;
  text-align: center;
  border-radius: 10px;
  background: #171e4f;
  color: #ffffff;
  font-weight: 700;
  &:hover {
    cursor: pointer;
    color: #ff5722;
  }
}
