@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

.match-sign-up {
  margin: 1rem 1rem 0.5rem 1rem;
  button {
    font-family: 'Outfit', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 3rem;
    color: #fff;
    background-color: var(--color);
    border: 2px solid var(--color);
    border-radius: 10px;
    margin-right: 0.5rem;
    transition: transform 0.5s ease;
    cursor: pointer;
    &:hover {
      animation: pulse 1s;
      box-shadow: 0 0 0 50px transparent;
      transform: translateY(-0.4rem) scale(1.05);
    }
  }
}

$colors: (
  cancel: #ff3a3a,
  play: #171e4f,
);

@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 20deg)};
  }
}
