.player-banner-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 4rem;
  width: 100%;
}

.player-banner-logo {
  display: flex;
  align-self: flex-end;

  .player-logo {
    display: flex;
    width: 20vw;
    max-width: 250px;
    margin-top: 2rem;
  }
}

.player-banner-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 20vw;
  max-width: 300px;

  .player-banner-intro-name {
    display: flex;

    text-align: right;
    font-family: 'GmarketSansTTFBold';
    font-size: 3rem;
    font-style: italic;
  }

  .player-banner-intro-line {
    display: flex;
    align-self: flex-end;

    font-family: 'Cafe24Simplehae';
    font-size: 0.7rem;
    font-style: italic;
  }

  .player-banner-intro-tags {
    display: flex;
    justify-content: space-evenly;

    font-family: 'Cafe24Simplehae';
    font-size: 0.8rem;

    .player-banner-intro-single-tag {
      display: flex;
      padding: 0.1rem 0.3rem 0.1rem 0.3rem;
      border-radius: 0.5rem;
      border: 0.05rem solid white;
    }
  }
}

@media screen and (max-width: 680px) {
  .player-banner-logo-container {
    margin-top: 1rem;
    .player-logo {
      min-width: 150px;
    }
  }
}

@media screen and (max-width: 370px) {
  .player-banner-logo-container {
    justify-content: flex-start;
  }
}
