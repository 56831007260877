.verify-page-wrapper {
  max-width: 864px;
  width: 80%;
  margin: 3.125rem auto;
  flex: 1;
}

.verify-page-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  h1 {
    color: #171e4f;
  }
}

#login-visible .account-box {
  visibility: visible;
}

.verify-message-wrapper {
  padding: 2.5rem 0;
  width: 100%;
  color: #3a3a3a;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  gap: 1.25rem;
}

.return-home-button {
  width: 9rem;
  background-color: #171e4f;
  color: #ffffff;
  font-weight: 700;
  line-height: 2.5;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #ff5722;
  }
}
