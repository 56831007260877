.match-roster {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  * {
    font-family: 'Outfit', sans-serif;
  }
}

.starters-container {
  width: 60%;
  position: relative;
  display: flex;
  height: 100%;
}

.roster-background {
  display: flex;
  width: 100%;
}

.starters-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.starters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1rem;
  height: 100%;
  gap: 0.5rem;
}

.home-bench,
.away-bench {
  flex: 1;
  padding: 1rem 0;
}

@media screen and (max-width: 860px) {
  .match-roster {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .starters-container {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      width: 100%;
    }
    .home-bench,
    .away-bench {
      grid-row: 2 / 3;
      margin: 1rem 0;
      padding: 0 1rem;
    }
    .home-bench {
      grid-column: 1 / 2;
      border-right: white 1px solid;
    }
    .away-bench {
      grid-column: 2 / 3;
    }
  }
}

.match-team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem 0.5rem;
  .MuiButtonBase-root {
    font-size: 1.5rem;
    line-height: 1;
    color: black;
    height: 4.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 800;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
