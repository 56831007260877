.select-players-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  border: 1px solid black;
  border-radius: 4px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
    border: 1px solid #ff5722;
    color: #ff5722;
  }
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
}
