.confidential-page-wrapper {
  flex: 1;
}

.change-confidential-container {
  margin: 4.688rem auto;
  width: 80%;
  max-width: 864px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.change-confidential-wrapper {
  width: 100%;
}

.change-confidential-form {
  padding: 1.563rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.change-confidential-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 2.2;
  background: #171e4f;
  color: #ffffff;
  padding-left: 1.25rem;
}

.save-changes-button-wrapper {
  display: flex;
  justify-content: center;
}

.save-changes-button {
  margin-top: 1.875rem;
  background: #171e4f;
  color: #ffffff;
  padding: 0.625rem 1.25rem;
  border: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  &:hover {
    cursor: pointer;
    color: #ff5722;
  }
}

.not-verified-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  padding: 1.25rem 0;
}
