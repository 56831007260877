.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.home-carousel-wrapper {
  max-width: 1080px;
  margin: 0.5rem 0;
}

.content-wrapper {
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.main-content-box {
  column-gap: 1.5rem;
  margin: 0.5rem;
  display: grid;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
  }
}

.main-grid-item-wrapper {
  position: relative;
  width: 100%;
  min-width: 0;
  span {
    font-size: 1rem;
    font-weight: 700;
    color: #171e4f;
    margin: 0.5rem 0;
  }
  .carousel-root {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .carousel.carousel-slider {
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.main-carousel-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 0.5rem;
}

.main-team-standing-table {
  width: 100%;
  border-collapse: collapse;
}

.clickable:hover {
  cursor: pointer;
}
