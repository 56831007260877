.roster-player-profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .roster-player-profile {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    padding: 0.7rem 0.7rem;
    border-radius: 2rem;
    transition: all ease 0.5s;
    cursor: pointer;
    font-weight: 700;
    &:hover {
      color: #ff5722;
      transform: translate(0, -5px) scale(1.1);
    }
    @media screen and (max-width: 480px) {
      padding: 0.3rem 0.7rem;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
}

.home-starters {
  color: #000000;
  .roster-player-profile {
    background-color: #ffffff;
    font-weight: 800;
    @media screen and (max-width: 480px) {
      font-weight: 500;
    }
  }
}

.away-starters {
  .roster-player-profile {
    background-color: #000000;
    color: #ffffff;
  }
}
