.league-detail-wrapper {
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.standings-loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.leader-board-grid {
  column-gap: 2rem;
  margin-bottom: 1rem;
  display: grid;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
