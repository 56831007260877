.schedule-list-wrapper {
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.schedule-nav-bar {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  margin: 0 0.5rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #171e4f;
}

.schedule-list-grid {
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.nav-button {
  text-decoration: none;
  color: #171e4f;
  &:hover {
    color: #ff5722;
    cursor: pointer;
  }
}

.nav-button-disabled {
  text-decoration: none;
  color: #171e4f;

  pointer-events: none;
}

.schedule-content-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50rem;
}

.end-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 2;
  margin: 0 0.5rem;
}

.no-content {
  width: 100%;
}
