.home-starters,
.away-starters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: 95%;
  width: 50%;
  margin: auto 0;
}

.home-starters {
  .select-starters-button-wrapper {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  .player-PG {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
  .player-SG {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }
  .player-PF {
    grid-row: 5 / 6;
  }
  .player-C {
    grid-row: 3 / 4;
  }
}

.away-starters {
  .select-starters-button-wrapper {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .player-PG {
    grid-row: 2 / 3;
  }
  .player-SG {
    grid-row: 4 / 5;
  }
  .player-SF {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  .player-PF {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
  }
  .player-C {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }
}
