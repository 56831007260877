.my-player-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 2.2;
  background: #171e4f;
  color: #ffffff;
  padding-left: 1.25rem;
}

.my-player-container {
  padding: 1.563rem 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #ffffff;
}

.account-type-wrapper {
  font-size: 15px;
  line-height: 2.2;
}

.profile-detail-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 2.2;
}
