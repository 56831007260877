.player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.player-careerhigh-currentseason-container {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  // margin-top: 3.5rem;
  padding: 0.5rem;
  max-width: calc(1080px - 1rem);
  width: 100%;
}

@media screen and (max-width: 680px) {
  .player-careerhigh-currentseason-container {
    flex-wrap: wrap;
    & > div {
      max-width: 100%;
    }
  }
}
