.player-match-images-wrapper {
  max-width: calc(1080px - 1rem);
  width: 100%;
  margin: 0.5rem auto; // Center the component
  display: flex;
  flex-direction: row; // Horizontal layout
  gap: 10px; // Spacing between columns

  .matches-column,
  .previews-column,
  .current-image-column {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .matches-column {
    flex: 1; // Adjust the flex value to change the width ratio
    overflow-y: auto;
    max-height: 600px;

    .match-item {
      padding: 10px;
      cursor: pointer;
      border-bottom: 10px solid #f0f0f0; // Horizontal separator between elements

      &:hover {
        background-color: #f0f0f0;
      }

      p {
        margin: 5px 0;
        font-size: 0.9rem;
        color: #333;
        font-weight: 500;
      }
    }
  }

  .previews-column {
    flex: 2; // Adjust the flex value to change the width ratio
    overflow-y: auto;
    max-height: 600px;
    gap: 5px;

    img {
      width: 100%;
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
  }

  .current-image-column {
    flex: 3; // Adjust the flex value to change the width ratio
    img {
      width: 100%;
      height: auto;
    }
  }
}
